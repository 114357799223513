import { ReactComponent as ListChecks } from "./svgs/ListChecks.svg";
import { ReactComponent as Swap } from "./svgs/Swap.svg";
import { ReactComponent as GridFour } from "./svgs/GridFour.svg";
import { ReactComponent as Article } from "./svgs/Article.svg";
import { ReactComponent as FileMagnifyingGlass } from "./svgs/FileMagnifyingGlass.svg";
import { ReactComponent as ArrowClick } from "./svgs/ArrowClick.svg";
const IconMap = {
  ListChecks,
  Swap,
  Article,
  GridFour,
  FileMagnifyingGlass,
  ArrowClick,
};
export default IconMap;
