import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SettingOutlined } from "@ant-design/icons";

import NgDropdownMenu from "../../../components/ng-dropdown-menu";
import { NgTextTooltip } from "../../../components/text-tooltip/ng-text-tooltip";
import Tooltip from "../../../components/tooltip/ng-tooltip";
import { NextGenPalette } from "../../../utils/color";
import { classesName } from "../../../utils/css";
import { dataSourceSupportsMetricType } from "../../../utils/datasource";
import {
  MetricCategory,
  QueryScope,
  TableColumnTypeCategory,
  isFileSource as getIsFileSource,
} from "../../../utils/enums";
import { profilerEnabledFnSorter } from "../../../utils/profiler-util";
import { EVENT, PAGE, getTableDetailProps, trackEvent } from "../../../utils/telemetry";
import {
  updateProfilerConfigColumnList,
  getProfilerConfigTableColumnList,
  getProfilerConfigTableColumnListUsage,
} from "../../../actions/profiler/profiler-action";
import {
  ProfileTableColumnBooleanTypeIcon,
  ProfileTableColumnNumberTypeIcon,
  ProfileTableColumnOtherTypeIcon,
  ProfileTableColumnStringTypeIcon,
  ProfileTableColumnTimestampTypeIcon,
} from "../icons";
import ProfilerConfirmationDialog from "../profiler-confirmation-dialog";
import { tableConfigChangeEventTracking } from "../takeover/table/table-tracking";
import { hasPermission } from "../../../utils/uri-path";
import { AppPermissions } from "../../../utils/permissions";
import { ManageTabHeader, ManageTabNgTable } from "../manage-tabs";
import { columnFn, safeToLowerCase } from "../../../components/entity-list/columns";
import ProfilerEnableView from "../profiler-enable-view";
import NgButton, { TextWithIcon } from "../../../components/button/ng-button";
import { ProfilerConfigureTableIcon } from "../../../components/icons/profiler-configure-table-icon";
import { NgTableClickableText, NgTableTheme } from "../../../components/table/ng-table";
import { treeNodeColumnPermissions } from "../tree-data";
import ColumnMaskedIndicator from "../../../components/column/column-masked-indicator";
import useBatchUpdate from "../use-batch-update";
import Switch from "../../../atom/switch";
import useNavigationBlocker from "../../../hooks/useNavigationBlocker";
import { capitalizeFirstLetter } from "../../../utils/general";

import "./profiler-data-source-table-manage-columns-tab.scss";

const columnTypeIconMapper = {
  [TableColumnTypeCategory.NUMERIC]: ProfileTableColumnNumberTypeIcon,
  [TableColumnTypeCategory.STRING]: ProfileTableColumnStringTypeIcon,
  [TableColumnTypeCategory.TIMESTAMP]: ProfileTableColumnTimestampTypeIcon,
  [TableColumnTypeCategory.BOOLEAN]: ProfileTableColumnBooleanTypeIcon,
};

function isDistributionMetricEnabled(row) {
  let { columnTypeCategory, profilerConfig } = row;
  columnTypeCategory = profilerConfig.columnTypeCategory || columnTypeCategory;
  if (
    ![TableColumnTypeCategory.STRING, TableColumnTypeCategory.NUMERIC].includes(
      columnTypeCategory
    )
  ) {
    return false;
  }

  if (columnTypeCategory === TableColumnTypeCategory.NUMERIC && profilerConfig.masked) {
    return false;
  }

  return columnTypeCategory === TableColumnTypeCategory.STRING
    ? profilerConfig?.categoricalDistribution.enabled
    : profilerConfig?.numericalDistribution.enabled;
}

function getMenuItems(columnTypeCategory, onChange, iconProps) {
  const menuItems = [];
  if (columnTypeCategory === TableColumnTypeCategory.STRING) {
    menuItems.push({
      onClick: () => onChange(TableColumnTypeCategory.NUMERIC),
      icon: <ProfileTableColumnNumberTypeIcon {...iconProps} />,
      label: "Numeric",
    });
  } else if (columnTypeCategory === TableColumnTypeCategory.NUMERIC) {
    menuItems.push({
      onClick: () => onChange(TableColumnTypeCategory.STRING),
      icon: <ProfileTableColumnStringTypeIcon {...iconProps} />,
      label: "String",
    });
  }
  return menuItems;
}

function ProfilerSettingTableCategoryTypeColumn({
  columnTypeCategory,
  onChange,
  disabled = false,
}) {
  const TypeIcon =
    columnTypeIconMapper[columnTypeCategory] || ProfileTableColumnOtherTypeIcon;
  const iconProps = {
    height: 20,
    width: 20,
    fillColor: NextGenPalette.systemBlack,
  };

  const categoryTypeDisplayText = capitalizeFirstLetter(
    columnTypeCategory || TableColumnTypeCategory.OTHER
  );
  const iconToRender = (
    <Tooltip title={categoryTypeDisplayText}>
      <span>
        <TypeIcon {...iconProps} />
      </span>
    </Tooltip>
  );

  const canUpdateType = [
    TableColumnTypeCategory.STRING,
    TableColumnTypeCategory.NUMERIC,
  ].includes(columnTypeCategory);

  if (!canUpdateType) {
    return iconToRender;
  }

  const menuItems = getMenuItems(columnTypeCategory, onChange, iconProps);
  const menuTrigger = (
    <button
      className={classesName(
        "profiler-manage-columns-table-type-button",
        disabled && "disabled"
      )}
      disabled={disabled}
    >
      {iconToRender}
    </button>
  );

  return !disabled ? (
    <NgDropdownMenu trigger={menuTrigger} menuItems={menuItems} />
  ) : (
    menuTrigger
  );
}

function ProfilerDataSourceTableManageColumnsTab(props) {
  const {
    availableNodeKeys,
    columnList,
    columnListUsage,
    config,
    loading,
    workspaceUserPermissions,
    workspaceUuid,
    getCurrentProfilerColumnListUsage,
    getProfilerConfigTableColumnList,
    onConfigureTableClick,
    onCurrentSelectNodeChange,
    updateProfilerConfigColumnList,
  } = props;

  const {
    currentEntityList: currentColumnList,
    toggleAction,
    batchList,
    isDirty,
    reset,
  } = useBatchUpdate({
    entityList: columnList,
    getEntityKey: (column) => column.uuid,
    entityUpdate: (column, payload) => {
      const { actionType, newValue, isColumnAvailable } = payload;
      const oldProfilerConfig = column.profilerConfig;
      const columnTypeCategory =
        column.profilerConfig.columnTypeCategory || column.columnTypeCategory;

      if (!isColumnAvailable(column)) {
        return column;
      }

      if (actionType === "masked") {
        return {
          ...column,
          profilerConfig: {
            ...column.profilerConfig,
            masked: newValue,
            categoricalDistribution: {
              ...oldProfilerConfig.categoricalDistribution,
              enabled:
                newValue === true
                  ? false
                  : oldProfilerConfig.categoricalDistribution.enabled,
            },
            categoryListChange: {
              ...oldProfilerConfig.categoryListChange,
              enabled:
                newValue === true
                  ? false
                  : oldProfilerConfig.categoryListChange.enabled,
            },
          },
        };
      }
      if (actionType === "status") {
        return {
          ...column,
          profilerConfig: {
            ...oldProfilerConfig,
            enabled: newValue,
          },
        };
      }
      if (actionType === "missingValue") {
        const oldMissingValueConfig = oldProfilerConfig.missingValue
          ? oldProfilerConfig.missingValue
          : {};
        return {
          ...column,
          profilerConfig: {
            ...oldProfilerConfig,
            missingValue: {
              ...oldMissingValueConfig,
              enabled: newValue,
            },
          },
        };
      }
      if (actionType === "distribution") {
        if (
          ![TableColumnTypeCategory.NUMERIC, TableColumnTypeCategory.STRING].includes(
            columnTypeCategory
          ) ||
          !dataSourceSupportsMetricType(
            dataSource,
            MetricCategory.DISTRIBUTION,
            columnTypeCategory
          )
        ) {
          return { ...column };
        }

        if (columnTypeCategory === TableColumnTypeCategory.NUMERIC) {
          const oldNumericalDistributionConfig = oldProfilerConfig.numericalDistribution
            ? oldProfilerConfig.numericalDistribution
            : {};
          return {
            ...column,
            profilerConfig: {
              ...oldProfilerConfig,
              numericalDistribution: {
                ...oldNumericalDistributionConfig,
                enabled: newValue,
              },
            },
          };
        } else {
          const oldDataDistributionConfig = oldProfilerConfig.categoricalDistribution
            ? oldProfilerConfig.categoricalDistribution
            : {};
          return {
            ...column,
            profilerConfig: {
              ...oldProfilerConfig,
              categoricalDistribution: {
                ...oldDataDistributionConfig,
                enabled: newValue,
              },
            },
          };
        }
      }
      if (actionType === "categoryList") {
        if (columnTypeCategory !== TableColumnTypeCategory.STRING) {
          return { ...column };
        }

        const oldCategoryListConfig = oldProfilerConfig.categoryListChange || {};
        return {
          ...column,
          profilerConfig: {
            ...oldProfilerConfig,
            categoryListChange: {
              ...oldCategoryListConfig,
              enabled: newValue,
            },
          },
        };
      }
      if (actionType === "columnTypeCategory") {
        return {
          ...column,
          profilerConfig: {
            ...oldProfilerConfig,
            columnTypeCategory: newValue,
          },
        };
      }

      console.log(`Unknown type ${actionType}`);
    },
    ignoreKeys: ["removedTs", "lastScannedTs", "lastSeenTs", "firstSeenTs"],
  });

  useNavigationBlocker(
    isDirty,
    "You have unsaved changes. Do you want to leave without saving?",
    reset
  );

  const canEdit = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
  ]);

  // BACKEND_APPS_USER_VIEWS_EDIT_WORKSPACEUSERLIST is a hack to check if current user is app admin
  const isAdmin = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_USER_VIEWS_EDIT_WORKSPACEUSERLIST,
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_COLUMNPROFILERCONFIGDETAILVIEW,
  ]);

  useEffect(() => {
    if (config.dataSource?.metadata.uuid && config.table?.uuid) {
      getProfilerConfigTableColumnList(
        workspaceUuid,
        config.dataSource,
        config.table,
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspaceUuid,
    config.dataSource?.metadata.uuid,
    config.table?.uuid,
    config.table?.profilerConfig?.enabled,
    getProfilerConfigTableColumnList,
  ]);

  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentSelectColumnKeys, setCurrentSelectColumnKeys] = useState([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [currentConfirmationDialogConfig, setCurrentConfirmationDialogConfig] =
    useState(null);

  if (!config.dataSource || !config.table) {
    return null;
  }

  const { dataSource, table, schema } = config;

  const isFileSource = getIsFileSource(dataSource?.config?.connection?.type);
  const isTableConfigured = table.profilerConfig?.enabled;

  const isFullTableQueryScope =
    table.profilerConfig?.queryScope === QueryScope.FULL_TABLE;

  function isColumnTypeCategoryAvailable(columnData) {
    const isColumnConfigured = columnData.profilerConfig.enabled;
    return isColumnConfigured && isTableConfigured;
  }

  function isCategoryActivityAvailable(columnData) {
    const profilerConfigDisabled =
      columnData.profilerConfig && !columnData.profilerConfig.enabled;
    const columnTypeCategory =
      columnData.profilerConfig.columnTypeCategory || columnData.columnTypeCategory;
    const isMasked = columnData.profilerConfig.masked;

    return (
      !isMasked &&
      !profilerConfigDisabled &&
      columnTypeCategory === TableColumnTypeCategory.STRING &&
      !isFullTableQueryScope &&
      isTableConfigured
    );
  }

  function isDistributionMetricAvailable(columnData) {
    const columnTypeCategory =
      columnData.profilerConfig?.columnTypeCategory || columnData.columnTypeCategory;
    const unsupportedColumnType = ![
      TableColumnTypeCategory.STRING,
      TableColumnTypeCategory.NUMERIC,
    ].includes(columnTypeCategory);
    const profilerConfigDisabled =
      columnData.profilerConfig && !columnData.profilerConfig.enabled;
    const isMasked = columnData.profilerConfig.masked;

    return (
      !isMasked &&
      !unsupportedColumnType &&
      !profilerConfigDisabled &&
      dataSourceSupportsMetricType(
        dataSource,
        MetricCategory.DISTRIBUTION,
        columnTypeCategory
      ) &&
      isTableConfigured
    );
  }

  function isNullPercentMetricAvailable(columnData) {
    const profilerConfigDisabled =
      columnData.profilerConfig && !columnData.profilerConfig.enabled;

    return !profilerConfigDisabled && isTableConfigured;
  }

  function updateColumns(workspaceUuid, dataSource, table, paramsList) {
    setIsUpdateInProgress(true);
    updateProfilerConfigColumnList(
      workspaceUuid,
      dataSource,
      table,
      paramsList
    ).finally(() => {
      setIsUpdateInProgress(false);
      getProfilerConfigTableColumnList(
        workspaceUuid,
        config.dataSource,
        config.table,
        true
      );
    });
  }

  function onProfilerConfigChange(
    currentColumnUuid,
    actionType,
    newValue,
    isColumnAvailable = () => true
  ) {
    const currentBulkColumnUuids =
      currentSelectColumnKeys.length > 0
        ? currentSelectColumnKeys
        : [currentColumnUuid];

    tableConfigChangeEventTracking(newValue, actionType, currentBulkColumnUuids, {
      ...getTableDetailProps(table, schema, dataSource),
      page: PAGE.MANAGE_COLUMNS,
    });

    toggleAction(currentBulkColumnUuids, { actionType, newValue, isColumnAvailable });
  }

  function saveChanges() {
    if (batchList.length === 0) {
      console.log("There is no valid column object to be updated");
      return;
    }

    const columnListTypeCategoryMap = columnList.reduce((obj, column) => {
      obj[column.uuid] = column.profilerConfig?.columnTypeCategory;
      return obj;
    }, {});

    const isMaskedGoingToBeEnabledList = batchList.filter(
      (column) => column.profilerConfig?.masked
    );
    const isProfiledGoingToBeDisabledList = batchList.filter(
      (column) => !column.profilerConfig?.enabled
    );
    const isColumnTypeCategoryToBeUpdatedList = batchList.filter(
      (column) =>
        columnListTypeCategoryMap[column.uuid] !==
        column.profilerConfig?.columnTypeCategory
    );

    if (
      isMaskedGoingToBeEnabledList.length === 0 &&
      isProfiledGoingToBeDisabledList.length === 0 &&
      isColumnTypeCategoryToBeUpdatedList.length === 0
    ) {
      updateColumns(workspaceUuid, dataSource, table, batchList);
    } else {
      setIsUpdateInProgress(true);
      const opts = {};
      isMaskedGoingToBeEnabledList.length > 0 && (opts["checkMasked"] = true);
      isColumnTypeCategoryToBeUpdatedList.length > 0 && (opts["checkCast"] = true);
      getCurrentProfilerColumnListUsage(
        workspaceUuid,
        dataSource,
        table,
        batchList.map(({ uuid }) => uuid),
        opts
      )
        .then(({ data }) => {
          const affectedMetrics = data?.totalMetrics ?? 0;
          const actionMessages = [];

          if (affectedMetrics > 0) {
            if (isProfiledGoingToBeDisabledList.length > 0) {
              const indicatorName = batchList.map(({ name }) => name).join(", ");
              actionMessages.push(
                `deactivate column ${indicatorName}, which has no metrics or monitors`
              );
            }
            if (isColumnTypeCategoryToBeUpdatedList.length > 0) {
              const indicatorName = batchList.map(({ name }) => name).join(", ");
              actionMessages.push(
                `cast column ${indicatorName} type, which has no metrics or monitors`
              );
            }
            if (isMaskedGoingToBeEnabledList.length > 0) {
              actionMessages.push(
                `delete ${affectedMetrics} metrics. These metrics are not supported when Column Masking is on because they would reveal data values`
              );
            }

            const defaultConfirmationMsg =
              actionMessages.length === 1
                ? `You are about to ${actionMessages[0]}`
                : `You are about to ${actionMessages.join(", ")}`;

            const currentConfirmationDialogConfig = {
              title: "Confirm changes",
              defaultConfirmationMsg,
              context: batchList,
            };

            setIsConfirmationDialogOpen(true);
            setCurrentConfirmationDialogConfig(currentConfirmationDialogConfig);
          } else {
            updateColumns(workspaceUuid, dataSource, table, batchList);
          }
          setIsUpdateInProgress(false);
        })
        .catch((err) => {
          console.log(`Fail to get column list usage due to ${err}`);
          setIsUpdateInProgress(false);
        });
    }
  }

  function onStatusChange(columnUuid, newEnabled) {
    onProfilerConfigChange(columnUuid, "status", newEnabled);
  }

  function onMaskChange(columnUuid, newEnabled) {
    onProfilerConfigChange(columnUuid, "masked", newEnabled);
  }

  function onDistributionChange(columnUuid, newEnabled) {
    onProfilerConfigChange(
      columnUuid,
      "distribution",
      newEnabled,
      isDistributionMetricAvailable
    );
  }

  function onMissingValueChange(columnUuid, newEnabled) {
    onProfilerConfigChange(
      columnUuid,
      "missingValue",
      newEnabled,
      isNullPercentMetricAvailable
    );
  }

  function onColumnTypeCategoryChange(columnUuid, newColumnTypeCategory) {
    console.log({ newColumnTypeCategory });
    onProfilerConfigChange(
      columnUuid,
      "columnTypeCategory",
      newColumnTypeCategory,
      isColumnTypeCategoryAvailable
    );
  }

  function onCategoryListChange(columnUuid, newEnabled) {
    onProfilerConfigChange(
      columnUuid,
      "categoryList",
      newEnabled,
      isCategoryActivityAvailable
    );
  }

  function onSelectChange(newSelectedRowKeys) {
    setCurrentSelectColumnKeys(newSelectedRowKeys);
  }

  const rowSelection = {
    selectedRowKeys: currentSelectColumnKeys,
    onChange: onSelectChange,
  };

  const canViewColumn = hasPermission(
    workspaceUserPermissions,
    treeNodeColumnPermissions
  );

  const renderMaskColumnIcon = (masked) => {
    return masked && !isAdmin && <ColumnMaskedIndicator tooltip="Column Masked" />;
  };

  let columnsToHide = [];

  if (!isAdmin) {
    columnsToHide.push("profilerConfigMask");
  }

  const columns = [
    columnFn({
      title: (
        <Tooltip title="Toggle to make column active or inactive in the explorer tree">
          Active
        </Tooltip>
      ),
      key: "profilerConfig",
      width: "10%",
      render: function (profilerConfig, data) {
        const { enabled = false } = profilerConfig;
        return (
          <Switch
            size="small"
            disabled={!canEdit || isUpdateInProgress}
            checked={enabled}
            onChange={(checked) => onStatusChange(data.uuid, checked)}
          />
        );
      },
      getCompareVal: (_indexedValue, row) => row.profilerConfig.enabled,
    })({ dataIndex: "profilerConfig" }),

    columnFn({
      title: (
        <Tooltip title="Toggle to mask column values in Lightup reports such as data profile, sample data, and failing records">
          Mask
        </Tooltip>
      ),
      key: "profilerConfigMask",
      width: "10%",
      render: function (profilerConfig, data) {
        const { masked = false } = profilerConfig;
        return (
          <Switch
            size="small"
            checked={masked}
            disabled={!canEdit || isUpdateInProgress}
            onChange={(checked) => onMaskChange(data.uuid, checked)}
          />
        );
      },
      getCompareVal: (_indexedValue, row) => row.profilerConfig.masked,
    })({ dataIndex: "profilerConfig" }),
    columnFn({
      title: "Columns",
      key: "columnName",
      width: "25%",
      render: function (columnName, row) {
        const { masked } = row.profilerConfig;

        return (
          <TextWithIcon
            icon={renderMaskColumnIcon(masked)}
            inline={false}
            iconPosition="left"
          >
            {canViewColumn && availableNodeKeys.has(row.uuid) ? (
              <NgTableClickableText
                onClick={() => {
                  onCurrentSelectNodeChange({
                    dataSource,
                    schemaInfo: schema,
                    tableInfo: table,
                    columnInfo: row,
                  });
                }}
              >
                <NgTextTooltip>{columnName}</NgTextTooltip>
              </NgTableClickableText>
            ) : (
              <NgTextTooltip>{columnName}</NgTextTooltip>
            )}
          </TextWithIcon>
        );
      },
      getCompareVal: (_indexedValue, row) => safeToLowerCase(row.columnName),
      defaultSortOrder: "ascend",
    })({ dataIndex: "columnName" }),
    columnFn({
      title: "Type",
      key: "columnTypeCategory",
      width: "9%",
      render: function (columnTypeCategory, data) {
        columnTypeCategory =
          data.profilerConfig.columnTypeCategory || columnTypeCategory;

        return (
          <ProfilerSettingTableCategoryTypeColumn
            columnTypeCategory={columnTypeCategory}
            onChange={(newType) => onColumnTypeCategoryChange(data.uuid, newType)}
            disabled={
              !isColumnTypeCategoryAvailable(data) ||
              isFileSource ||
              isUpdateInProgress ||
              !canEdit
            }
          />
        );
      },
      getCompareVal: (_indexedValue, row) => row.columnTypeCategory,
    })({ dataIndex: "columnTypeCategory" }),
    {
      title: (
        <Tooltip title="Category activity measures category values added or removed from the column (applicable for string columns)">
          Category Activity
        </Tooltip>
      ),
      key: "profilerCategoryListChange",
      dataIndex: "profilerConfig",
      width: "14%",
      render: function (profilerConfig, data) {
        const enabled = profilerConfig?.categoryListChange?.enabled;
        return (
          <Switch
            size="small"
            disabled={
              !isCategoryActivityAvailable(data) || !canEdit || isUpdateInProgress
            }
            checked={enabled}
            onChange={(checked) => onCategoryListChange(data.uuid, checked)}
          />
        );
      },
      sorter: {
        compare: profilerEnabledFnSorter((row) =>
          row.profilerConfig?.categoryListChange?.enabled ? 1 : 0
        ),
      },
    },
    {
      title: (
        <Tooltip title="Distribution measures the distribution of values in the column">
          Distribution
        </Tooltip>
      ),
      key: "profilerConfigDistribution",
      dataIndex: "profilerConfig",
      width: "17%",
      render: function (profilerConfig, data) {
        const enabled = isDistributionMetricEnabled(data);
        return (
          <Switch
            size="small"
            disabled={
              !isDistributionMetricAvailable(data) || !canEdit || isUpdateInProgress
            }
            checked={enabled}
            onChange={(checked) => onDistributionChange(data.uuid, checked)}
          />
        );
      },
      sorter: {
        compare: profilerEnabledFnSorter((row) =>
          isDistributionMetricEnabled(row) ? 1 : 0
        ),
      },
    },
    {
      title: (
        <Tooltip title="Null % measures the percentage of nulls in the column">
          Null Percent
        </Tooltip>
      ),
      key: "profilerConfigMissingValues",
      dataIndex: "profilerConfig",
      width: "12%",
      render: function (profilerConfig, data) {
        const enabled = profilerConfig?.missingValue?.enabled;
        return (
          <Switch
            size="small"
            disabled={
              !isNullPercentMetricAvailable(data) || !canEdit || isUpdateInProgress
            }
            checked={enabled}
            onChange={(checked) => onMissingValueChange(data.uuid, checked)}
          />
        );
      },
      sorter: {
        compare: profilerEnabledFnSorter((row) =>
          row?.profilerConfig.missingValue.enabled ? 1 : 0
        ),
      },
    },
  ];

  const authorizedColumns =
    columnsToHide.length === 0
      ? columns
      : columns.filter((item) => !columnsToHide.includes(item.key));

  const filteredColumnList = [];
  for (let currentColumn of currentColumnList) {
    const { columnName } = currentColumn;
    if (
      table.profilerConfig.queryScope === QueryScope.TIME_RANGE &&
      columnName === table.profilerConfig.timestampColumn
    ) {
      continue;
    }

    if (
      searchText.length > 0 &&
      columnName.toLowerCase().indexOf(searchText.toLowerCase()) === -1
    ) {
      continue;
    }

    filteredColumnList.push(currentColumn);
  }

  const rightControls = (
    <>
      <NgButton
        outline
        testId="manage-column-cancel-button"
        disabled={!isDirty || isUpdateInProgress}
        onClick={reset}
      >
        Cancel
      </NgButton>
      <NgButton
        testId="manage-column-save-button"
        disabled={!isDirty || isUpdateInProgress}
        onClick={saveChanges}
      >
        {isUpdateInProgress ? "Saving..." : "Save"}
      </NgButton>
    </>
  );

  return !isTableConfigured ? (
    <ProfilerEnableView
      gridOnly
      fullHeight
      icon={<ProfilerConfigureTableIcon />}
      description="Configure your table to access columns"
      buttonContent={
        canEdit ? (
          <TextWithIcon icon={<SettingOutlined />}>Configure Table</TextWithIcon>
        ) : null
      }
      onClick={() => onConfigureTableClick()}
    />
  ) : (
    <div className="profiler-manage-columns">
      <ManageTabHeader
        entityName="column"
        lastScannedTs={table.lastScannedTs}
        lastScannedStatus={table.status?.lastScannedStatus}
        searchText={searchText}
        setSearchText={setSearchText}
        rightControls={rightControls}
      />
      <div className="profiler-manage-columns-table-container">
        <ManageTabNgTable
          entityName="column"
          theme={NgTableTheme.DARK}
          rowSelection={rowSelection}
          dataSource={filteredColumnList}
          columns={authorizedColumns}
          rowKey={"uuid"}
          loading={loading}
        />
      </div>
      {isConfirmationDialogOpen && currentConfirmationDialogConfig && (
        <ProfilerConfirmationDialog
          modalIsOpen={isConfirmationDialogOpen}
          setIsOpen={(isConfirmationDialogOpen) =>
            setIsConfirmationDialogOpen(isConfirmationDialogOpen)
          }
          okClicked={(paramsList) => {
            trackEvent(
              paramsList.length > 1
                ? EVENT.DEACTIVATE_COLUMN_BULK
                : EVENT.DEACTIVATE_COLUMN,
              {
                ...getTableDetailProps(table, schema, dataSource),
                page: PAGE.MANAGE_COLUMNS,
              }
            );

            updateColumns(workspaceUuid, dataSource, table, paramsList);
          }}
          usage={columnListUsage}
          title={currentConfirmationDialogConfig.title}
          context={currentConfirmationDialogConfig.context}
          defaultConfirmationMsg={
            currentConfirmationDialogConfig.defaultConfirmationMsg
          }
          enableUsage={currentConfirmationDialogConfig.enableUsage}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.profilerReducer.profilerConfigTableColumnList.loading,
  columnList: state.profilerReducer.profilerConfigTableColumnList.data,
  columnListUsage: state.profilerReducer.profilerConfigTableColumnListUsage,
  waffle: state.userReducer.currentUserInfo.waffle,
});

const mapDispatchToProps = (dispatch) => ({
  getProfilerConfigTableColumnList: (
    workspaceUuid,
    dataSource,
    table,
    enableProfilerConfigCheck
  ) =>
    dispatch(
      getProfilerConfigTableColumnList(
        workspaceUuid,
        dataSource,
        table,
        enableProfilerConfigCheck
      )
    ),
  getCurrentProfilerColumnListUsage: (
    workspaceUuid,
    dataSource,
    table,
    columnUuids,
    opts
  ) =>
    dispatch(
      getProfilerConfigTableColumnListUsage(
        workspaceUuid,
        dataSource,
        table,
        columnUuids,
        opts
      )
    ),
  updateProfilerConfigColumnList: (workspaceUuid, dataSource, table, paramsList) =>
    dispatch(
      updateProfilerConfigColumnList(workspaceUuid, dataSource, table, paramsList, true)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilerDataSourceTableManageColumnsTab);
