import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Modal from "react-modal";
import { getStore } from "./app/store";
import history from "./app/history";
import { configureSentry } from "./utils/sentry";
import App from "./app/app";
import { getCurrentNow, NowContext } from "./utils/now";
import "./styles/antd.css";
import "./index.scss";
import "./styles/typography.scss";
import "./elements/Colors/colors.scss";

if (window.SENTRY_DSN !== "") {
  console.log("Configuring Sentry", window.SENTRY_DSN);
  configureSentry(
    window.SENTRY_DSN,
    window.SENTRY_ENV,
    process.env.CIRCLE_SHA1 || "default_sentry_release"
  );
}

Modal.setAppElement("#root");

const store = getStore();

if (process.env.REACT_APP_USE_MOCK_SERVICE_WORKER === "true") {
  const { mocksWorker } = require("./mocks/browser");
  const worker = mocksWorker([
    // Add desired mock handlers here.
  ]);
  worker.start();
}

ReactDOM.render(
  <Provider store={store}>
    <HashRouter history={history}>
      <NowContext.Provider value={getCurrentNow}>
        <App />
      </NowContext.Provider>
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);
