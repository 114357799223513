import axios from "../utils/api";
import { get } from "lodash";
import {
  IncidentStatus,
  getIncidentScore,
  getIncidentValidationStatus,
  IncidentCreatorType,
  IncidentType,
  IncidentCommentLogType,
} from "../utils/enums";
import { backendRelativeURIPath, getAPIURIInstance } from "../utils/uri-path";
import queryString from "query-string";

function getCreatorInfoFromMap(creatorInfoMapper, ref, creatorUuid) {
  if (!creatorInfoMapper.hasOwnProperty(creatorUuid)) {
    creatorInfoMapper[creatorUuid] = getCreateInfo(ref, creatorUuid);
  }

  return creatorInfoMapper[creatorUuid];
}

function getCreateInfo(ref, creatorUuid) {
  const { filterMap, streamMap, sourceMap } = ref;
  if (!filterMap[creatorUuid]) {
    console.log(`Fail to find filter for ${creatorUuid}`);
    return null;
  }

  const filterInfo = filterMap[creatorUuid];
  const streamUuid = filterInfo.config.metrics[0];
  if (!streamUuid) {
    console.log(`Invalid streamUuid for ${creatorUuid}`);
    return null;
  }

  const kpiInfo = streamMap[streamUuid];
  if (!kpiInfo) {
    console.log(`Fail to find stream ${streamUuid} for ${creatorUuid}`);
    return null;
  }

  const sourceUuid = kpiInfo.config.sources[0];
  if (!sourceUuid) {
    console.log(`Invalid sourceUuid for ${creatorUuid}`);
    return null;
  }

  const dataSourceInfo = sourceMap[sourceUuid];
  if (!dataSourceInfo) {
    console.log(`Fail to find source ${sourceUuid} for ${creatorUuid}`);
    return null;
  }

  return {
    type: IncidentCreatorType.FILTER,
    creatorUuid,
    dataSourceInfo,
    kpiInfo,
    filterInfo,
  };
}

function getIncidentListFromServer(
  rawIncidentList,
  readFilterUuids,
  annotateResponse,
  queryStartTime,
  queryEndTime
) {
  const normalizedIncidentList = [];
  const { data, ref } = rawIncidentList;
  const groupedIncidentMapper = {};
  const creatorInfoMapper = {};
  for (let currentIncident of data) {
    let filterUuid = currentIncident["filter_uuid"];
    const incidentType = currentIncident["incident_type"];
    const failingRecordsCount = get(
      currentIncident,
      "failing_records_count.count",
      "N/A"
    );

    // UI should skip event incident since it is deprecated
    if (incidentType !== IncidentType.ANOMALY) {
      console.log(`Skipping event incident for ${filterUuid}`);
      continue;
    }

    // For preview
    if (readFilterUuids.length > 0) {
      filterUuid = readFilterUuids[0];
    }

    const slice = currentIncident["slice"];
    const groupByKey = JSON.stringify({
      filterUuid,
      slice,
    });
    if (!groupedIncidentMapper.hasOwnProperty(groupByKey)) {
      const creatorInfo = annotateResponse
        ? getCreatorInfoFromMap(creatorInfoMapper, ref, filterUuid)
        : null;
      if (annotateResponse && !creatorInfo) {
        console.log(
          `Dropping incident for we can not find the createInfo for ${filterUuid}.`
        );
        continue;
      }

      const newGroupedIncidentItem = {
        id: filterUuid,
        slice,
        creatorInfo,
        isExpand: false,

        // Incident information.
        incidents: [],

        startTime: queryStartTime,
        endTime: queryEndTime,
        failingRecordsCount,
      };

      normalizedIncidentList.push(newGroupedIncidentItem);
      groupedIncidentMapper[groupByKey] = newGroupedIncidentItem;
    }

    const { incidents } = groupedIncidentMapper[groupByKey];
    const startTime = Math.floor(currentIncident["start_ts"]);
    const endTime = Math.floor(currentIncident["close_ts"]);
    const creationTime = Math.floor(currentIncident["creation_ts"]);
    if (startTime > endTime) {
      console.log(
        `Ignore invalid anomaly ${JSON.stringify(
          currentIncident
        )} for wrong time range.`
      );
      continue;
    }

    const duration = endTime - startTime + 1;
    incidents.push({
      id: currentIncident["id"],
      uuid: currentIncident["uuid"],
      filterUuid,
      startTime,
      endTime,
      creationTime,
      duration,
      slicedAffected: 0,
      slice: currentIncident["slice"],
      status: currentIncident["status"],
      score: getIncidentScore(currentIncident["impact"]),
      direction: currentIncident["direction"],
      ongoing: currentIncident["ongoing"],
      reason: currentIncident["reason"],
      validation: currentIncident["validation"],
      validationStatus: getIncidentValidationStatus(currentIncident["validation"]),
      failingRecordsCount,
    });
  }
  return normalizedIncidentList;
}

export function getIncidentListPromise(
  workspaceUuid,
  queryObject,
  annotateResponse = true,
  apiOptions = {},
  isRawResponse = false
) {
  return new Promise(function (resolve, reject) {
    const {
      limit,
      startTime,
      endTime,
      filterUuids = [],
      previewUuids = [],
      customerOptions = {},
    } = queryObject;

    if (previewUuids.length > 0 && filterUuids.length === 0) {
      console.log("PreviewUuid and filterUuids must be provided at the same time!");
    }

    let queryStringArr = [];
    if (typeof limit === "number") {
      queryStringArr.push(`limit=${limit}`);
    }

    if (startTime) {
      queryStringArr.push(`start_ts=${startTime}`);
    }

    if (endTime) {
      queryStringArr.push(`end_ts=${endTime}`);
    }

    const statusQueryOptions = [
      IncidentStatus.UNVIEWED,
      IncidentStatus.VIEWED,
      IncidentStatus.SUBMITTED,
      IncidentStatus.CLOSED,
    ];

    if (customerOptions && customerOptions["rejected"]) {
      statusQueryOptions.push(IncidentStatus.REJECTED);
    }

    queryStringArr.push(`status_list=${statusQueryOptions.join(",")}`);

    let realFilterUuids = previewUuids.length > 0 ? filterUuids : [];
    let filterUuidParams = previewUuids.length > 0 ? previewUuids : filterUuids;
    if (filterUuidParams && filterUuidParams.length > 0) {
      queryStringArr.push(`monitor_uuids=${filterUuidParams.join(",")}`);
    }

    if (annotateResponse) {
      queryStringArr.push(`with_ref=true`);
    }

    const queryString = queryStringArr.length > 0 ? `?${queryStringArr.join("&")}` : "";
    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.INCIDENT_LIST, {
          workspaceUuid,
        })}${queryString}`,
        apiOptions
      )
      .then(function (response) {
        const incidentList = isRawResponse
          ? response.data
          : getIncidentListFromServer(
              response.data,
              realFilterUuids,
              annotateResponse,
              startTime,
              endTime
            );
        resolve(incidentList);
      })
      .catch(function (err) {
        console.log(`Fail to get all incidents for ${err}`);
        reject(err);
      });
  });
}

export function getIncidentPromise(workspaceUuid, uuid, disableToast) {
  return new Promise(function (resolve, reject) {
    const fetchIncidentURL = getAPIURIInstance(backendRelativeURIPath.INCIDENT, {
      workspaceUuid,
      uuid,
    });

    axios
      .get(fetchIncidentURL, {
        disableToast,
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function updateIncidentStatusPromise(workspaceUuid, uuid, status) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(
        getAPIURIInstance(backendRelativeURIPath.INCIDENT, {
          workspaceUuid,
          uuid,
        }),
        { status }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(`Fail to update incident ${uuid} status to ${status} for ${error}`);
        reject(error);
      });
  });
}

export function updateIncidentValidationStatusPromise(workspaceUuid, uuid, validation) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(
        getAPIURIInstance(backendRelativeURIPath.INCIDENT, {
          workspaceUuid,
          uuid,
        }),
        validation
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(
          `Fail to update incident ${uuid} validation status to ${JSON.stringify(
            validation
          )} for ${error}`
        );
        reject(error);
      });
  });
}

export function endIncidentPromise(workspaceUuid, uuid) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(
        getAPIURIInstance(backendRelativeURIPath.INCIDENT, {
          workspaceUuid,
          uuid,
        }),
        { endIncident: true }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.log("Fail to end incident");
        reject(error);
      });
  });
}

function getIncidentCommentFromServer(rawData, currentUserId) {
  return {
    ...rawData,
    isEditable:
      rawData.type === IncidentCommentLogType.USER_GENERATED &&
      rawData.ownedBy?.id === currentUserId,
  };
}

function getIncidentCommentFromUI(incidentComment) {
  const { incidentId, creatorId, slice, comment, createTime } = incidentComment;

  return {
    incident_id: incidentId,
    creator_id: creatorId,
    slice,
    comment,
    create_ts: createTime,
  };
}

export function getIncidentCommentListPromise(workspaceUuid, uuid, currentUserId) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        getAPIURIInstance(backendRelativeURIPath.INCIDENT_COMMENT_LIST, {
          workspaceUuid,
          uuid,
        }),
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve(
          response.data.comments.map((currentComment) =>
            getIncidentCommentFromServer(currentComment, currentUserId)
          )
        );
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function addIncidentCommentPromise(
  workspaceUuid,
  uuid,
  newComment,
  currentUserId
) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(backendRelativeURIPath.INCIDENT_COMMENT_LIST, {
          workspaceUuid,
          uuid,
        }),
        getIncidentCommentFromUI(newComment),
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve(getIncidentCommentFromServer(response.data, currentUserId));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateIncidentCommentPromise(
  workspaceUuid,
  uuid,
  id,
  comment,
  currentUserId
) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.INCIDENT_COMMENT, {
          workspaceUuid,
          uuid,
          id,
        }),
        { comment },
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve(getIncidentCommentFromServer(response.data, currentUserId));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function deleteIncidentCommentPromise(workspaceUuid, uuid, id) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(
        getAPIURIInstance(backendRelativeURIPath.INCIDENT_COMMENT, {
          workspaceUuid,
          uuid,
          id,
        }),
        { baseURL: "/api/v0/" }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getIncidentSampleDataPromise(
  workspaceUuid,
  incidentUuid,
  previewUuid,
  additional = {},
  apiOptions = {}
) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(
          backendRelativeURIPath.INCIDENT_SAMPLE_DATA,
          {
            workspaceUuid,
          },
          { baseURL: "/api/v0" }
        ),
        { incidentUuid, previewUuid, ...additional },
        apiOptions
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function terminateIncidentSampleDataPromise(
  workspaceUuid,
  incidentUuid,
  previewUuid
) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        getAPIURIInstance(
          backendRelativeURIPath.INCIDENT_SAMPLE_DATA_TERMINATION,
          {
            workspaceUuid,
          },
          { baseURL: "/api/v0" }
        ),
        { incidentUuid, previewUuid }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getIncidentCorrelatedMetricsPromise(
  workspaceUuid,
  incidentUuid,
  opts = {}
) {
  return new Promise(function (resolve, reject) {
    const { startTime, endTime } = opts;
    const queryParams = {};
    if (startTime) {
      queryParams["start_ts"] = startTime;
    }
    if (endTime) {
      queryParams["end_ts"] = endTime;
    }
    const queryStr =
      Object.keys(queryParams).length > 0
        ? `?${queryString.stringify(queryParams)}`
        : "";
    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.INCIDENT_CORRELATED_METRICS, {
          workspaceUuid,
          uuid: incidentUuid,
        })}${queryStr}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getIncidentUserDefinedRelatedMetricsPromise(
  workspaceUuid,
  incidentUuid
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `${getAPIURIInstance(
          backendRelativeURIPath.INCIDENT_USER_DEFINED_RELATED_METRICS,
          {
            workspaceUuid,
            uuid: incidentUuid,
          }
        )}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function getIncidentConfigPromise(workspaceUuid, incidentId, opts = {}) {
  const { startTime, endTime, withRef = true } = opts;
  const queryParams = { with_ref: withRef };
  if (startTime) {
    queryParams["start_ts"] = startTime;
  }
  if (endTime) {
    queryParams["end_ts"] = endTime;
  }
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `${getAPIURIInstance(backendRelativeURIPath.INCIDENT_CONFIG2, {
          workspaceUuid: workspaceUuid,
          uuid: incidentId,
        })}?${queryString.stringify(queryParams)}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function updateIncidentConfigPromise(workspaceUuid, incidentId, config) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        getAPIURIInstance(backendRelativeURIPath.INCIDENT_CONFIG2, {
          workspaceUuid: workspaceUuid,
          uuid: incidentId,
        }),
        config
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
