import { classesName } from "../../utils/css";
import InputLabel from "../InputLabel";
import InputHelp from "../InputHelp";
import { InputStatus } from "./enums";

import "./input-wrappers.scss";

export function withLabelAndHelp(WrappedComponent, componentName, customClassName) {
  const WithLabelAndHelp = (props) => {
    const {
      label,
      tooltipText,
      status,
      isOptional,
      className = "",
      testId,
      helpText,
      helpLink,
      helpLinkText,
      optionalText = "Optional",
      wrapperStyle = {},
      ...restProps
    } = props;

    return (
      <div
        className={classesName(
          "lightup-input",
          customClassName,
          status === InputStatus.ERROR && "input-has-error",
          className
        )}
        style={wrapperStyle}
        data-testid={testId}
      >
        <InputLabel
          label={label}
          tooltipText={tooltipText}
          optionalText={optionalText}
          isOptional={isOptional}
          testId={testId && `${testId}-label`}
        />
        <WrappedComponent {...restProps} />
        <InputHelp
          helpLink={helpLink}
          helpText={helpText}
          helpLinkText={helpLinkText}
          testId={testId && `${testId}-help`}
        />
      </div>
    );
  };

  WithLabelAndHelp.displayName = componentName;

  return WithLabelAndHelp;
}
