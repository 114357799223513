import React from "react";
import "./inputhelp.scss";
export default function InputHelp(props) {
  if (!props.helpText && !props.helpLink && !props.helpLinkText) {
    return null;
  }
  return (
    <div className="input-help-section" data-testid={props.testId}>
      {props.helpText && <span className="input-help-text">{props.helpText}</span>}
      {props.helpLink && (
        <a
          target="_blank"
          rel="noreferrer"
          href={props.helpLink || "#"}
          className="input-help-link"
        >
          {props.helpLinkText || props.helpLink}
        </a>
      )}
    </div>
  );
}
